import React, { useCallback, useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';

const Animation = ({ animationData, autoplay, classNames, height, loop, playOnViewportVisibility, width }) => {
  const [animationStarted, setAnimationStarted] = useState(false);

  const animationContainer = useRef(null);
  const options = {
    loop,
    autoplay,
    animationData,
    renderer: 'svg',
    rendererSettings: {
      className: classNames,
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleScroll = useCallback(() => {
    if (playOnViewportVisibility && animationContainer?.current?.el && window) {
      const animationElement = animationContainer.current.el;
      const elementBounds = animationElement.getBoundingClientRect();
      const bottomPosition = elementBounds.bottom;

      const windowHeight = window.innerHeight;

      if (bottomPosition > 0 && bottomPosition < windowHeight && !animationStarted) {
        setAnimationStarted(true);
        animationContainer.current.play();
      }
    }
  }, [animationStarted, playOnViewportVisibility]);

  const handleClick = () => {
    restartAnimation();
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      restartAnimation();
    }
  };

  const restartAnimation = () => {
    if (!loop && animationContainer?.current?.anim && animationContainer.current.anim.isPaused) {
      animationContainer.current.stop();
      animationContainer.current.play();
    }
  };

  useEffect(() => {
    if (playOnViewportVisibility) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (playOnViewportVisibility) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  });

  useEffect(() => {
    handleScroll();
  }, [handleScroll]);

  return (
    <div
      onClick={handleClick}
      onKeyDown={event => handleKeyDown(event)}
      role="button"
      tabIndex={0}
    >
      <Lottie
        ref={animationContainer}
        options={options}
        height={height}
        width={width}
        isClickToPauseDisabled={true}
      />
    </div>
  );
};

Animation.propTypes = {
  animationData: PropTypes.object,
  autoplay: PropTypes.bool,
  classNames: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  loop: PropTypes.bool,
  playOnViewportVisibility: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

Animation.defaultProps = {
  animationData: {},
  autoplay: true,
  classNames: null,
  height: 400,
  loop: true,
  playOnViewportVisibility: false,
  width: 400
};

export default Animation;
